import {
    SALARY_PROJECT_ADDITIONAL_DOCUMENT_BONUS_FINE_UPDATE_MODAL,
    SALARY_PROJECT_ADDITIONAL_DOCUMENT_BONUSES_FINES_CREATE_MODAL,
    SALARY_PROJECT_ADDITIONAL_DOCUMENT_READY_MARK_BY_SHIFT_OPEN,
    SALARY_PROJECT_CREATE_SHIFT_MODAL,
    SALARY_PROJECT_FILTER_SHIFT,
    SALARY_PROJECT_READINESS_REPORT,
    SALARY_PROJECT_READINESS_REPORT_GET_ORDER_DETAILING,
    SALARY_PROJECT_READY_MARK_GOODS_SERVICE_MODAL,
    SALARY_PROJECT_READY_MARK_PACKING_PART_FOR_ORDER_MODAL,
    SALARY_PROJECT_READY_MARK_PART_FOR_ORDER_CHOICE_SERVICE_MODAL,
    SALARY_PROJECT_READY_MARK_PART_FOR_ORDER_MODAL,
    SALARY_PROJECT_READY_MARK_TOGGLE_ALL,
    SALARY_PROJECT_REPORT_PAGE,
    SALARY_PROJECT_SCANNER_READ_MARK_TOGGLE_ALL,
    SALARY_PROJECT_SEARCH_ORDERS_TOGGLE_ALL,
    SALARY_PROJECT_SET_DEFAULT_PLANE,
    SALARY_PROJECT_SET_DEFAULT_PLANE_CREATE_MODAL,
    SALARY_PROJECT_SET_EQUIPMENT_WORKER_GROUP,
    SALARY_PROJECT_SET_GENERAL_CURRENCY,
    SALARY_PROJECT_SET_GENERAL_GOODS_SERVICE,
    SALARY_PROJECT_SET_GENERAL_GOODS_SERVICE_TYPE, SALARY_PROJECT_SET_GENERAL_ORDERS_STATUSES,
    SALARY_PROJECT_SET_RATES,
    SALARY_PROJECT_SET_RATES_CREATE_MODAL,
    SALARY_PROJECT_SET_SHIFT,
    SALARY_PROJECT_SET_SHIFT_OPEN,
    SALARY_PROJECT_SET_SHIFT_PLANE,
    SALARY_PROJECT_SET_USERS,
    SALARY_PROJECT_SHIFT_PLANE_CREATE_MODAL,
    SALARY_PROJECT_SHIFT_PLANE_UPDATE_MODAL,
    SALARY_PROJECT_SHIFT_WORKER_GROUP_MODAL,
    SALARY_PROJECT_UPDATE_SHIFT_MODAL
} from "../actions/SalaryProject";

export const INITIAL_RATES_MODAL_CREATE_FORM = {
    service: null,
    price_bad: null,
    price_good: null,
    price_super: null,
    currency: null
}
export const INITIAL_DEFAULT_PLANE_MODAL_CREATE_FORM = {
    service_type: null,
    count_bad: null,
    count_normal: null,
    count_super: null
}
export const INITIAL_SHIFT_MODAL_UPDATE_FORM = {
    date_start: null,
    koef: null,
    shift: null
}
export const INITIAL_SHIFT_FILTER = {
    offset: 0, open_for_edit: 1
}
export const INITIAL_SHIFT_PLANE = {
    shift: null,
    service_type: null,
    count_plan: null,
    comment: null,
    salary_shift_plan_id: null
}
export const INITIAL_SHIFT_WORKER_GROUP_FORM = {
    shift: null,
    fields: [{_id: null, percentage: null}]
}
export const READY_MARK_INITIAL_VALUES = {
    orders: null,
    filter: {
        production_task: 0,
        order_id: null,
        service_type: []
    },
    shift_form_values: {
        shift: null,
        worker_group: null
    },
    selected_orders: [],
    equipment_worker_group_by_shift: null,

    // choice_shift: null
}
export const SEARCH_ORDER_INITIAL_VALUES = {
    orders: null,
    filter: {
        production_task: 0,
        order_id: null,
        service_type: [],
        general: 1,
        // working_group: "2"
    },

}
export const SCANNER_READ_MARK_INITIAL_VALUES = {
    shift_form_values: {
        shift: null,
        worker_group: null
    },
    filter: {
        // заказ/деталь
        type_order_or_part: 0, // order scanner value default
        // добавить/убрать отметку
        type_add_or_delete_mark: 0, // убрать отметку  value default
        // упаковка/услуга
        type_package_or_service: 0, // упаковка value default
        service_type: [], // id услуг
        service_type_package_id: 17,
        type_need_confirm: 0, // нет value default
    },
    confirm_modal: {
        isOpen: false,
        information: null,
        data_for_api: null
    },
    start_scanner: false,
    isSuccessRequest: false,
    equipment_worker_group_by_shift: null
}
export const READINESS_REPORT_INITIAL_VALUES = {
    orders: null,
    order_detailing: {}

}
const INITIAL_STATE = {
    default_plane: null,
    default_plane_create_modal: {
        isOpen: false,
        form_values: {
            ...INITIAL_DEFAULT_PLANE_MODAL_CREATE_FORM
        }
    },
    rates: null,
    rates_modal: {
        isOpen: false,
        form_values: {
            ...INITIAL_RATES_MODAL_CREATE_FORM
        }

    },
    general_currencies: null,
    general_goods_service: null,
    general_goods_service_type: null,
    shift: null,
    shift_create_modal: {
        isOpen: false
    },
    shift_update_modal: {
        isOpen: false,
        form_values: {...INITIAL_SHIFT_MODAL_UPDATE_FORM}
    },
    shift_filter: {...INITIAL_SHIFT_FILTER},
    shift_open: null,
    additional_document_by_shift_open: {
        documents: null,
        ready_mark: null
    },
    shift_plane: null,
    shift_plane_create_modal: {
        isOpen: false,
        form_values: {...INITIAL_SHIFT_PLANE}
    },
    shift_plane_update_modal: {
        isOpen: false,
        form_values: {...INITIAL_SHIFT_PLANE}
    },
    equipment_worker_group: null,
    shift_worker_group_modal: {
        isOpen: false,
        form_values: {...INITIAL_SHIFT_WORKER_GROUP_FORM}
    },
    ready_mark: {
        ...READY_MARK_INITIAL_VALUES
    },
    ready_mark_goods_service_modal: {
        isOpen: false
    },
    ready_mark_part_form_order_modal: {
        isOpen: false,
        data: null,
        filter: null,
        choice_service: {
            service_type_add: null,
            service_id: null
        }
    },
    ready_mark_packing_part_form_order_modal: {
        isOpen: false,
        data: null,
    },
    users: null,
    readiness_report: {
        ...READINESS_REPORT_INITIAL_VALUES
    },
    additional_document_bonuses_fines_create_modal: {
        isOpen: false,
        type_action: null,
        additional_document: null,
        shift_id: null
    },
    additional_document_bonus_fine_update_modal: {
        isOpen: false,
        type_action: null,
        data: null,
        shift_id: null
    },
    scanner_read_mark: {
        ...SCANNER_READ_MARK_INITIAL_VALUES
    },
    salary_report: {
        data: null,
    },
    search_orders: {
        ...SEARCH_ORDER_INITIAL_VALUES
    },
    general_orders_statuses: null,

}

const SalaryProjectReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SALARY_PROJECT_SEARCH_ORDERS_TOGGLE_ALL: {
            return {
                ...state, search_orders: {
                    ...state.search_orders,
                    ...action.payload
                }
            }
        }
        case SALARY_PROJECT_REPORT_PAGE: {
            return {
                ...state, salary_report: {
                    ...state.salary_report,
                    ...action.payload
                }
            }
        }
        case SALARY_PROJECT_READY_MARK_PACKING_PART_FOR_ORDER_MODAL: {
            return {
                ...state,
                ready_mark_packing_part_form_order_modal: {
                    ...state.ready_mark_packing_part_form_order_modal,
                    ...action.payload
                }
            }
        }
        case SALARY_PROJECT_SCANNER_READ_MARK_TOGGLE_ALL: {
            return {
                ...state,
                scanner_read_mark: {
                    ...state.scanner_read_mark,
                    ...action.payload
                }
            }
        }
        case SALARY_PROJECT_ADDITIONAL_DOCUMENT_BONUS_FINE_UPDATE_MODAL: {
            return {
                ...state,
                additional_document_bonus_fine_update_modal: {
                    ...state.additional_document_bonus_fine_update_modal,
                    ...action.payload
                }
            }
        }
        case SALARY_PROJECT_ADDITIONAL_DOCUMENT_BONUSES_FINES_CREATE_MODAL: {
            return {
                ...state,
                additional_document_bonuses_fines_create_modal: {
                    ...state.additional_document_bonuses_fines_create_modal,
                    ...action.payload
                }
            }
        }
        case SALARY_PROJECT_ADDITIONAL_DOCUMENT_READY_MARK_BY_SHIFT_OPEN: {
            return {
                ...state,
                additional_document_by_shift_open: {
                    ...state.additional_document_by_shift_open,
                    ...action.payload
                }
            }
        }
        case SALARY_PROJECT_READINESS_REPORT_GET_ORDER_DETAILING: {
            return {
                ...state,
                readiness_report: {
                    ...state.readiness_report,
                    order_detailing: action.payload
                }
            }
        }
        case SALARY_PROJECT_READINESS_REPORT: {
            return {
                ...state,
                readiness_report: {
                    ...state.readiness_report,
                    ...action.payload
                }
            }
        }
        case SALARY_PROJECT_READY_MARK_PART_FOR_ORDER_CHOICE_SERVICE_MODAL:
        case SALARY_PROJECT_READY_MARK_PART_FOR_ORDER_MODAL: {
            return {
                ...state,
                ready_mark_part_form_order_modal: {
                    ...state.ready_mark_part_form_order_modal,
                    ...action.payload
                }
            }
        }
        case SALARY_PROJECT_SET_DEFAULT_PLANE: {
            return {
                ...state,
                default_plane: action.payload
            }
        }
        case SALARY_PROJECT_SET_DEFAULT_PLANE_CREATE_MODAL: {
            return {
                ...state,
                default_plane_create_modal: {
                    ...state.default_plane_create_modal,
                    ...action.payload
                }
            }
        }

        case SALARY_PROJECT_SET_RATES: {
            return {
                ...state,
                rates: action.payload
            }
        }
        case SALARY_PROJECT_SET_RATES_CREATE_MODAL: {
            return {
                ...state,
                rates_modal: {
                    ...state.rates_modal,
                    ...action.payload
                }
            }
        }
        case SALARY_PROJECT_SET_GENERAL_CURRENCY: {
            return {
                ...state,
                general_currencies: action.payload
            }
        }
        case SALARY_PROJECT_SET_GENERAL_GOODS_SERVICE: {
            return {
                ...state,
                general_goods_service: action.payload
            }
        }
        case SALARY_PROJECT_SET_GENERAL_ORDERS_STATUSES: {
            return {
                ...state,
                general_orders_statuses: action.payload
            }
        }
        case SALARY_PROJECT_SET_GENERAL_GOODS_SERVICE_TYPE: {
            return {
                ...state,
                general_goods_service_type: action.payload
            }
        }
        case SALARY_PROJECT_SET_SHIFT: {
            return {
                ...state,
                shift: action.payload
            }
        }
        case SALARY_PROJECT_CREATE_SHIFT_MODAL: {
            return {
                ...state,
                shift_create_modal: {
                    ...state.shift_create_modal,
                    ...action.payload
                }
            }
        }
        case SALARY_PROJECT_UPDATE_SHIFT_MODAL: {
            return {
                ...state,
                shift_update_modal: {
                    ...state.shift_update_modal,
                    ...action.payload
                }
            }
        }
        case SALARY_PROJECT_FILTER_SHIFT: {
            return {
                ...state,
                shift_filter: {
                    ...state.shift_filter,
                    ...action.payload
                }
            }
        }
        case SALARY_PROJECT_SET_SHIFT_PLANE: {
            return {
                ...state,
                shift_plane: {
                    ...state.shift_plane,
                    ...action.payload
                }
            }
        }
        case SALARY_PROJECT_SET_SHIFT_OPEN: {
            return {
                ...state,
                shift_open: {
                    ...action.payload
                }
            }
        }

        case SALARY_PROJECT_SHIFT_PLANE_CREATE_MODAL: {
            return {
                ...state,
                shift_plane_create_modal: {
                    ...state.shift_plane_create_modal,
                    ...action.payload
                }
            }
        }

        case SALARY_PROJECT_SHIFT_PLANE_UPDATE_MODAL: {
            return {
                ...state,
                shift_plane_update_modal: {
                    ...state.shift_plane_update_modal,
                    ...action.payload
                }
            }
        }
        case SALARY_PROJECT_SET_EQUIPMENT_WORKER_GROUP: {
            return {
                ...state,
                equipment_worker_group: {
                    ...state.equipment_worker_group,
                    ...action.payload
                }
            }
        }

        case SALARY_PROJECT_SHIFT_WORKER_GROUP_MODAL: {
            return {
                ...state,
                shift_worker_group_modal: {
                    isOpen: action.payload?.isOpen,
                    form_values: {
                        // ...state.shift_worker_group_modal?.form_values,
                        ...action.payload?.form_values
                    }
                }
            }
        }
        case SALARY_PROJECT_READY_MARK_TOGGLE_ALL: {
            return {
                ...state,
                ready_mark: {
                    ...state.ready_mark,
                    ...action.payload
                }
            }
        }
        case SALARY_PROJECT_READY_MARK_GOODS_SERVICE_MODAL: {
            return {
                ...state,
                ready_mark_goods_service_modal: {
                    ...action.payload
                }
            }
        }
        case SALARY_PROJECT_SET_USERS: {
            return {
                ...state,
                users: action.payload
            }
        }

        default: {
            return state
        }
    }
}
export default SalaryProjectReducer;