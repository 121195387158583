import React, {useEffect, useMemo, useState} from 'react';
import ChoiceShift from "./component/ChoiceShift";
import {useDispatch, useSelector} from "react-redux";
import {getGeneralGoodsService, getScannerReadyMark, getShift} from "../../store/selectors/salary_project_selector";
import ContainerContent from "../../components/ui/ContainerContent";
import {getShiftThunk} from "../../store/thunks/salary_project/shifh-thunk";
import {scannerReadMarkToggleAllAC, setShiftFilterAC} from "../../store/actions/SalaryProject";
import {SCANNER_READ_MARK_INITIAL_VALUES} from "../../store/reducers/SalaryProjectReducer";
import {getGeneralGoodsServiceThunk} from "../../store/thunks/salary_project/general_thunk";
import {
    scannerReadyMarkBySubmitChoiceShiftThunk,
    scannerReadyMarkPutOrDeleteMarkThunk
} from "../../store/thunks/salary_project/ready_mark_thunk";
import ScannerFilterForm from "./component/ScannerFilterForm";
import QrScanner from "./component/QRScanner";
import SalaryScannerConfirmModal from "../../components/Modals/SalaryProject/SalaryScannerConfirmModal";
import PageTitle from "../../components/PageTitle/PageTitle";
import TextScanner from "./component/TextScanner";

const SalaryScannerReadMarkPage = ({getT}) => {
    const dispatch = useDispatch();
    const [textScannerVal, setTextScannerVal] = useState('');
    const scanner_read_mark = useSelector(getScannerReadyMark);
    const shift_state = useSelector(getShift);
    const general_goods_service = useSelector(getGeneralGoodsService);
    useEffect(() => {
        dispatch(setShiftFilterAC({offset: 0, open_for_edit: 1}));
        dispatch(getShiftThunk());
        dispatch(getGeneralGoodsServiceThunk());
    }, [dispatch]);


    //** проверка выбрана ли смена и бригада*/
    const isChoiceShift = useMemo(() => {
        return scanner_read_mark?.shift_form_values?.shift && scanner_read_mark?.shift_form_values.worker_group;
    }, [scanner_read_mark]);


    const handlerSubmitShift = (data) => {
        dispatch(scannerReadMarkToggleAllAC({
            shift_form_values: {...data},
        }));
        let equipment_workers_group = data?.shift?.worker_group?.find(item => Number(item?.salary_shift_worker_group_id) === Number(data?.worker_group));
        dispatch(scannerReadyMarkBySubmitChoiceShiftThunk(
            equipment_workers_group?.workers
        ))

    }
    const handlerResetShift = () => dispatch(scannerReadMarkToggleAllAC({
        ...SCANNER_READ_MARK_INITIAL_VALUES,
    }))
    const handlerToggleScannerButton = (val) => {
        // setIsEnabled(!scanner_read_mark.start_scanner)
        dispatch(scannerReadMarkToggleAllAC({
            start_scanner: val
        }));
    }
    const handlerToggleScannerTextButton = (val) => {
        // setIsEnabled(!scanner_read_mark.start_scanner)
        dispatch(scannerReadMarkToggleAllAC({
            start_scanner_text: val
        }));
    }
    const qrCodeSuccessCallback = (decodedText, decodedResult) => {
        dispatch(scannerReadyMarkPutOrDeleteMarkThunk({
            decode_text: decodedText,
            resetText: () => setTextScannerVal('')
        }))
    }

    const visibleBtn = useMemo(() => {
        let filter = scanner_read_mark?.filter;
        let type_order_or_part = !!Number(filter?.type_order_or_part);
        let type_add_or_delete_mark = !!Number(filter?.type_add_or_delete_mark);
        let type_package_or_service = !!Number(filter?.type_package_or_service);
        if (!type_order_or_part && type_add_or_delete_mark && !type_package_or_service) return false
        if (type_package_or_service && !filter?.service_type?.length) return false
        return true
    }, [scanner_read_mark?.filter])

    return (
        <ContainerContent>
            <PageTitle title={"scanner_ready_mark"}/>
            <div className="container-fluid ">
                <div className="row">
                    <div className={"col-12"}>
                        <ChoiceShift getT={getT} handlerSubmitShift={handlerSubmitShift}
                                     shift_form_values={scanner_read_mark.shift_form_values}
                                     shifts_data={shift_state?.data} handlerResetShift={handlerResetShift}/>
                    </div>
                    {isChoiceShift && <div className="col-12">
                        <ScannerFilterForm initial_form={scanner_read_mark.filter} getT={getT}
                                           services={general_goods_service?.data}
                                           equipment_worker_group_by_shift={scanner_read_mark.equipment_worker_group_by_shift}/>

                    </div>}

                    {isChoiceShift && visibleBtn && <div className="col-12 col-md-6 col-lg-4 mx-auto">
                        <div className={`${scanner_read_mark.start_scanner ? "disabled" : ""}`}>
                            <TextScanner onToggleButton={handlerToggleScannerTextButton}
                                         textScannerVal={textScannerVal}
                                         setTextScannerVal={setTextScannerVal}
                                         qrCodeSuccessCallback={qrCodeSuccessCallback}
                                         getT={getT} scanning_text={scanner_read_mark.start_scanner_text}/>

                        </div>
                        <div className={`${scanner_read_mark.start_scanner_text ? "disabled" : ""}`}>
                            <QrScanner scanning={scanner_read_mark.start_scanner}
                                       handlerToggleScanningButton={handlerToggleScannerButton}
                                       qrCodeSuccessCallback={qrCodeSuccessCallback} getT={getT}
                            />
                        </div>


                    </div>}
                </div>
            </div>
            {scanner_read_mark.confirm_modal.isOpen && <SalaryScannerConfirmModal getT={getT}/>}
        </ContainerContent>

    );
};

export default SalaryScannerReadMarkPage;