import React, {useEffect, useRef} from 'react';
import {Html5Qrcode} from "html5-qrcode";

const QrScanner = ({scanning, handlerToggleScanningButton, qrCodeSuccessCallback, getT}) => {
    const qrScannerRef = useRef(null);

    useEffect(() => {
        const qrScanner = new Html5Qrcode('reader');

        qrScannerRef.current = qrScanner;

        return () => {
            qrScanner.clear();
        };
    }, []);

    useEffect(() => {
        if (!qrScannerRef.current) return
        if (scanning) {
            startScanning();
        } else {
            stopScanning();
        }
    }, [scanning]);

    const startScanning = async () => {
        try {
            const qrScanner = qrScannerRef.current;
            const config = {fps: 10, qrbox: {width: 250, height: 250}}
            await qrScanner.start({facingMode: "environment"}, config, qrCodeSuccessCallback).then(() => {
                document.getElementById('reader-end').scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest"
                });
            })

        } catch (error) {
            console.error('Failed to start scanning:', error);
        }
    };

    const stopScanning = () => {
        if (qrScannerRef.current && qrScannerRef.current.isScanning) {
            qrScannerRef.current.stop();
        }
    };
    return (
        <div>
            {scanning ? (
                <div className="d-flex justify-content-center mb-2">
                    <button className={"btn btn-danger"} onClick={() => {
                        handlerToggleScanningButton(false)
                    }}> {getT("Остановить скан камерой")}</button>
                </div>
            ) : (
                <div className="d-flex justify-content-center mb-2">
                    <button className={"btn btn-success"} onClick={() => {
                        handlerToggleScanningButton(true)
                    }}>{getT("Сканировать камерой")}</button>
                </div>
            )}
            <div id="reader"></div>
            <div className="pb-5"></div>
            <div className="pb-5"></div>
            <div className="pb-5" id={"reader-end"}></div>

        </div>
    );
};

export default QrScanner;