import {dispatcherErrorThunk} from "../common_thunk";
import {
    readyMarGoodsServiceModalAC,
    readyMarkPackingPartForOrderModalAC,
    readyMarkPartForOrderModalModalAC,
    readyMarToggleAllAC,
    scannerReadMarkToggleAllAC,
    setReadinessReportAC,
    setReadinessReportOrderDetailingAC
} from "../../actions/SalaryProject";
import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import {OrderAPI} from "../../../api/Salary/OrderAPI";
import {createFormData, groupedOrderReadMark} from "../../../utils/helpers";
import {PutProdMarkDetailAPI} from "../../../api/Salary/PutProdMarkDetailAPI";
import {spread} from "axios";
import {getWorkerInParallelPromise} from "./equipment_worker_thunk";
import {
    createParallelRequestEntryForGroupedProductionMrkToDetail,
    createParallelRequestEntryForProductionMarkToDetail,
    getErrorResponseSpread
} from "../../utils/ready_mark_util";
import {LS_USER_ID, LS_WORKER_ID} from "../../../utils/constants";
import toast from "react-hot-toast";

//**
// Выбор смены и бригады. Запросить заказы по фильтру
// Получить всех работников которые входят в бригаду для получения доступных услуг которые могут отмечать
// или одного сотрудника (дописать логику если у нас не пользователь а работник в системе)
// */
//::TODO дописать логику если у нас не пользователь а работник в системе
export const readyMarkBySubmitChoiceShiftThunk = (filter, equipment_workers_group) => async dispatch => {
    try {
        let worker_in_system_id = JSON.parse(localStorage.getItem(LS_WORKER_ID))
        const is_has_equipment_workers_group = Array.isArray(equipment_workers_group);
        let equipment_response = null
        dispatch(toggleLoaderStatusAC(true));
        if (is_has_equipment_workers_group) {
            //::TODO проверка на работника в системе и получение данных
            // if(!!worker_in_system_id){
            //     let current_equipment_workers_group = equipment_workers_group?.filter(worker=> Number(worker?.worker) === Number(worker_in_system_id))
            //     console.log(current_equipment_workers_group, 'current_equipment_workers_group')
            //     equipment_response = await getWorkerInParallelPromise(current_equipment_workers_group);
            // }else {
            equipment_response = await getWorkerInParallelPromise(equipment_workers_group);
            // }
        }
        const response_orders = await OrderAPI.getList(filter);
        if (!!response_orders?.error) {
            dispatch(dispatcherErrorThunk(response_orders));
            dispatch(readyMarToggleAllAC({
                orders: null,
                filter: filter,
                selected_orders: [],
                equipment_worker_group_by_shift: equipment_response
            }))
            return
        }
        let grouped_orders = Object.values(groupedOrderReadMark(response_orders?.data))
        dispatch(readyMarToggleAllAC({
            orders: grouped_orders,
            filter: filter,
            selected_orders: [],
            equipment_worker_group_by_shift: equipment_response
        }))

        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}

export const readyMarkGetOrdersByFilterThunk = (filter) => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const response_orders = await OrderAPI.getList(filter);
        if (!!response_orders?.error) {
            dispatch(dispatcherErrorThunk(response_orders));
            dispatch(readyMarToggleAllAC({
                orders: null,
                filter: filter,
                selected_orders: []
            }))
            return
        }
        let grouped_orders = Object.values(groupedOrderReadMark(response_orders?.data))
        dispatch(readyMarToggleAllAC({
            orders: grouped_orders,
            filter: filter,
            selected_orders: []
        }))
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}

//** Отправка запросв на отметку услуги и получения всех заказов*/
async function dispatchedPutMarkToDetailAndGetOrders(parallel_request_entry, filter, dispatch) {
    const response = await Promise.all(parallel_request_entry.map((api_data) => PutProdMarkDetailAPI.addMarkToDetail(api_data))).then(
        spread((...allData) => {
            return allData
        })
    );
    // let errors_finds = response && response?.filter(item => item?.hasOwnProperty('error') && !!item?.error)?.map(error => error?.error)
    // let errors = [...new Set(errors_finds)]?.join('. ')
    let errors = getErrorResponseSpread(response)
    if (errors) {
        dispatch(dispatcherErrorThunk({error: errors}));
        // return new Promise((res) => res({error: errors}))
    }
    let alert = response?.filter(e => e?.data)?.map(e => e?.data?.alert)?.join(". ")

    if (alert) {
        toast.success(alert)
    }
    dispatch(readyMarkGetOrdersByFilterThunk({...filter}))
    return new Promise((res) => res(true))
}

//** Отправка запросв на УДАЛЕНИЕ отметкИ услуги и получения всех заказов*/
async function dispatchedDeleteMarkToDetailAndGetOrders(parallel_request_entry, filter, dispatch) {
    const response = await Promise.all(parallel_request_entry.map((api_data) => PutProdMarkDetailAPI.deleteMarkToDetail(api_data))).then(
        spread((...allData) => {
            return allData
        })
    );
    // let errors_finds = response && response?.filter(item => item?.hasOwnProperty('error') && !!item?.error)?.map(error => error?.error)
    // let errors = [...new Set(errors_finds)]?.join('. ')
    let errors = getErrorResponseSpread(response)
    if (errors) {
        dispatch(dispatcherErrorThunk({error: errors}));
        // dispatch(readyMarkGetOrdersByFilterThunk({...filter}))

        // return new Promise((res) => res({error: errors}))
    }
    let alert = response?.filter(e => e?.data)?.map(e => e?.data?.alert)?.join(". ")

    if (alert) {
        toast.success(alert)
    }
    dispatch(readyMarkGetOrdersByFilterThunk({...filter}))
    return new Promise((res) => res(true))
}

/**
 * putProductionMrkToDetailThunk. Запрос на отметку выполнения типа услуг и/или конкретной услуги в рамках заказа.
 *
 * @param {object} api_data -
 * @param {number} api_data.id -  id заказа
 * @param {number} api_data.shift -  id смены
 * @param {number|null} api_data.user -  id пользователя – устанавливается если отметку ставит пользователь
 * @param {number|null} api_data.equipment_worker -  id работника
 * @param {number} api_data.worker_group- id бригады
 * @param {array} api_data.services_entry - массив улсуг нужно перебрать и создать паралельные запросы отметку
 * @param {number|null} api_data.services_entry.services -   id типа услуг. если значение есть оптавляем только один запрос
 * @param {array} api_data.services_entry.children - id's услуг. api_data.services_entry.services - null, создаем запросы на отпавку с id

 */

export const putProductionMrkToDetailThunk = (api_data) => async (dispatch, getState) => {
    try {
        let filter_state = getState()?.salary_project?.ready_mark?.filter;
        dispatch(toggleLoaderStatusAC(true));
        let parallel_request_entry = createParallelRequestEntryForProductionMarkToDetail(api_data)

        await dispatchedPutMarkToDetailAndGetOrders(parallel_request_entry, {
            ...filter_state,
            worker_group: api_data?.worker_group
        }, dispatch)

        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}
export const deleteProductionMarkToDetailThunk = (api_data) => async (dispatch, getState) => {
    try {
        let filter_state = getState()?.salary_project?.ready_mark?.filter;
        dispatch(toggleLoaderStatusAC(true));

        let parallel_request_entry = createParallelRequestEntryForProductionMarkToDetail(api_data)

        await dispatchedDeleteMarkToDetailAndGetOrders(parallel_request_entry, {
            ...filter_state,
            worker_group: api_data?.worker_group
        }, dispatch)

        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}
/**
 * putGroupedProductionMrkToDetailThunk. Запрос на груповую отметку выполнения типа услуг и/или конкретной услуги в рамках заказов
 *
 * @param {object} api_data -
 * @param {number} api_data.shift -  id смены
 * @param {number|null} api_data.user -  id пользователя – устанавливается если отметку ставит пользователь
 * @param {number|null} api_data.equipment_worker -  id работника
 * @param {number} api_data.worker_group- id бригады
 * @param {array} api_data.services_entry - массив улсуг нужно перебрать и создать паралельные запросы отметку
 * @param {number|null} api_data.services_entry.services -   id типа услуг. если значение есть оптавляем только один запрос
 * @param {array} api_data.services_entry.children - id's услуг. api_data.services_entry.services - null, создаем запросы на отпавку с id
 * @param {array} api_data.services_entry.access_order - id's заказов кудо можно отправить отметку услуги
 */
export const putGroupedProductionMrkToDetailThunk = (api_data) => async (dispatch, getState) => {
    try {
        let filter_state = getState()?.salary_project?.ready_mark?.filter;
        dispatch(toggleLoaderStatusAC(true));

        let parallel_request_entry = createParallelRequestEntryForGroupedProductionMrkToDetail(api_data);
        let response = await dispatchedPutMarkToDetailAndGetOrders(parallel_request_entry, {
            ...filter_state,
            worker_group: api_data?.worker_group
        }, dispatch);
        if (!response.hasOwnProperty('error')) {
            dispatch(readyMarGoodsServiceModalAC({isOpen: false}));
            dispatch(readyMarToggleAllAC({
                selected_orders: []
            }));
        }
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}

export const deleteGroupedProductionMrkToDetailThunk = (api_data) => async (dispatch, getState) => {
    try {
        let filter_state = getState()?.salary_project?.ready_mark?.filter;
        dispatch(toggleLoaderStatusAC(true));
        let parallel_request_entry = createParallelRequestEntryForGroupedProductionMrkToDetail(api_data);
        dispatch(toggleLoaderStatusAC(false));
        let response = await dispatchedDeleteMarkToDetailAndGetOrders(parallel_request_entry, {
            ...filter_state,
            worker_group: api_data?.worker_group
        }, dispatch);
        if (!response.hasOwnProperty('error')) {
            dispatch(readyMarGoodsServiceModalAC({isOpen: false}));
            dispatch(readyMarToggleAllAC({
                selected_orders: []
            }));
        }
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}


async function getPartForOrderMarks(order_id, filter) {
    let form_data;

    if (filter && Object.keys(filter)?.length) {
        form_data = createFormData({id: order_id, ...filter});

    } else {
        form_data = createFormData({id: order_id});
    }
    return await PutProdMarkDetailAPI.getPartForOrderMarks(form_data);

}


/**
 * getPartsInformationForProductionMarksByOrder.получаем данные для отметок по деталям
 *
 * @param {number} order_id  -
 * @param {object || null} filter  -
 */
export const getPartsInformationForProductionMarksByOrder = (order_id, filter = null) => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));

        let response = await getPartForOrderMarks(order_id, filter)
        if (response?.error && !response?.data) {
            dispatch(dispatcherErrorThunk({error: response.error}))
        }
        if (response?.data && !response?.error) {
            let payload = {
                isOpen: true, data: response.data
            }
            if (filter && Object.keys(filter)?.length) {
                payload = {
                    ...payload, filter: filter
                }
            }

            dispatch(readyMarkPartForOrderModalModalAC(payload))
        }
        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e));

    }
}

/**
 * putProductionMarkToDetailThunk. Добавит снять отметку
 *
 * @param {number}  order_id -  номер заказ
 * @param {object | null}  put - инфо о добавление отметки
 * @param {number | null}  put.service_type_add -   тип услуги
 * @param {number | null}  put.service_add - улсуга
 * @param {array | null}  put.entry -  массыв с деталями для добавление отметки
 * @param {object | null}  remove -  инфо о добавление отметки
 * @param {number | null}  remove.service_type_add - тип услуги
 * @param {number | null}  remove.service_add -улсуга
 * @param {array | null}  remove.entry - массыв с деталями для удаление отметки
 * @returns  {undefined}  -
 */
export const putProductionMarkToDetailThunk = ({
                                                   order_id = null,
                                                   put = null,
                                                   remove = null,
                                               }) => async (dispatch, getState) => {
    try {
        let salary_project_store = getState().salary_project;
        let ready_mark_shift_form_values = salary_project_store?.ready_mark?.shift_form_values;
        let filter_data = salary_project_store?.ready_mark_part_form_order_modal?.filter;
        let is_error_put_or_delete = false;
        dispatch(toggleLoaderStatusAC(true));
        //::TODO дописать проверку на работника и юзера
        let api_data = {
            id: order_id,
            shift: ready_mark_shift_form_values.shift.salary_shift_id,
            user: localStorage.getItem(LS_USER_ID),
            equipment_worker: localStorage.getItem(LS_WORKER_ID),
            worker_group: ready_mark_shift_form_values.worker_group,
            part_for_service_add: null

        }
        if (remove) {
            let api_data_for_put = {
                ...api_data,
            }
            if (remove?.service_type_add) {
                api_data_for_put['service_type_add'] = remove.service_type_add;
            }
            if (remove?.service_add) {
                api_data_for_put['service_add'] = remove.service_add;

            }
            if (remove?.entry && Array.isArray(remove.entry) && remove?.entry?.length) {
                api_data_for_put['part_for_service_remove_MySQL_records_id'] = remove.entry;
            }

            const response_remove = await PutProdMarkDetailAPI.deleteMarkToDetail({
                ...api_data_for_put
            })

            let alert = response_remove?.data?.alert?.join(". ")
            if (alert) {
                toast.success(alert)
            }
            if (response_remove.hasOwnProperty('error') && response_remove.error) {
                is_error_put_or_delete = true
                dispatch(dispatcherErrorThunk({error: response_remove.error}))
            }
        }
        if (put) {
            let api_data_for_put = {
                ...api_data,
            }
            if (put?.service_type_add) {
                api_data_for_put['service_type_add'] = put.service_type_add;
            }
            if (put?.service_add) {
                api_data_for_put['service_add'] = put.service_add;

            }
            if (put?.entry && Array.isArray(put.entry) && put?.entry?.length) {
                api_data_for_put['part_for_service_add'] = put.entry;
            }
            const response_put = await PutProdMarkDetailAPI.addMarkToDetail({
                ...api_data_for_put
            })
            let alert = response_put?.data?.alert?.join(". ")
            if (alert) {
                toast.success(alert)
            }
            if (response_put.hasOwnProperty('error') && response_put.error) {
                dispatch(dispatcherErrorThunk({error: response_put.error}))
                is_error_put_or_delete = true
            }
        }


        if (!is_error_put_or_delete) {
            await dispatch(getPartsInformationForProductionMarksByOrder(order_id, filter_data))
        }
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        console.log(e)
        let error = e?.hasOwnProperty('error') ? e?.error : e?.message;
        dispatch(dispatcherErrorThunk({error: error}))
    }
}

/**
 * readinessReportGetOrdersByFilterThunk. Отчёт готовности
 *
 * @param {object} filter -
 * @returns  {undefined}  -
 */
export const readinessReportGetOrdersByFilterThunk = (filter) => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const response_orders = await OrderAPI.getList(filter);
        if (!!response_orders?.error) {
            dispatch(dispatcherErrorThunk(response_orders));
            dispatch(setReadinessReportAC({
                orders: null,
                filter: filter,
            }))
            return
        }
        let grouped_orders = Object.values(groupedOrderReadMark(response_orders?.data))
        dispatch(setReadinessReportAC({
            orders: grouped_orders,
            filter: filter,
        }))
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}

export const readinessReportGetMarkOfDetailingOrder = (order_id) => async (dispatch, getState) => {
    try {
        let readiness_order_detailing = getState().salary_project?.readiness_report?.order_detailing;
        let key_order = `order_${order_id}`;
        let current_order = readiness_order_detailing?.[key_order];

        if (!!current_order && current_order?.data) {
            let isOpen = current_order?.isOpen;
            dispatch(setReadinessReportOrderDetailingAC({
                ...readiness_order_detailing, [key_order]: {...current_order, isOpen: !isOpen}
            }))
            return
        }
        let form_data = createFormData({id: order_id});
        let response = await PutProdMarkDetailAPI.getPartForOrderMarks(form_data);
        if (response?.error && !response?.data) {
            dispatch(dispatcherErrorThunk({error: response.error}))
            return
        }
        dispatch(setReadinessReportOrderDetailingAC({
            ...readiness_order_detailing, [key_order]: {isOpen: true, data: response?.data}
        }))
    } catch (e) {
        console.log(e)
        dispatch(dispatcherErrorThunk(e));
    }

}


//**
// Выбор смены и бригады. Запросить данные о работниках
// Получить всех работников которые входят в бригаду для получения доступных услуг которые могут отмечать
// или одного сотрудника (дописать логику если у нас не пользователь а работник в системе)
// */
//::TODO дописать логику если у нас не пользователь а работник в системе
export const scannerReadyMarkBySubmitChoiceShiftThunk = (equipment_workers_group) => async dispatch => {
    try {
        const is_has_equipment_workers_group = Array.isArray(equipment_workers_group);
        let equipment_response = null
        dispatch(toggleLoaderStatusAC(true));
        if (is_has_equipment_workers_group) {
            equipment_response = await getWorkerInParallelPromise(equipment_workers_group);
        }

        dispatch(scannerReadMarkToggleAllAC({
            equipment_worker_group_by_shift: equipment_response
        }))

        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}

async function requestPackageMarkPutOrDeleteScannerDispatched(api_for_data) {
    console.log(api_for_data)
    let api_data = {
        "id": api_for_data?.link,
        user: localStorage.getItem(LS_USER_ID),
        equipment_worker: localStorage.getItem(LS_WORKER_ID),
    }
    let errors = null
    console.log(api_data)
    if (api_for_data.is_type_add_or_delete_mark) {
        // put mark
        return await PutProdMarkDetailAPI.addPackingMarkToDetailLink(api_data)
    } else {
        //delete mark
        return await PutProdMarkDetailAPI.removePackingMarkToDetailLink(api_data)

    }
}

async function requestServiceMarkPutOrDeleteScannerDispatched(api_for_data) {
    let api_data = createDataForToggleMarkScanner(api_for_data);
    let errors = null

    if (api_for_data.is_type_add_or_delete_mark) {
        // put mark
        return await Promise.all(api_data.map((api_data) => PutProdMarkDetailAPI.addMarkToDetail(api_data))).then(
            spread((...allData) => {
                return allData
            })
        );
    } else {
        //delete mark
        return await Promise.all(api_data.map((api_data) => PutProdMarkDetailAPI.deleteMarkToDetail(api_data))).then(
            spread((...allData) => {
                return allData
            })
        );

    }
}


async function requestMarkPutOrDeleteScannerDispatched(api_for_data, dispatch) {

    let response = null
    let errors = null
    if (api_for_data.is_service_type_package) {
        response = await requestPackageMarkPutOrDeleteScannerDispatched(api_for_data);
        errors = response?.hasOwnProperty('error') && !!response?.error ? response?.error : null
    } else {
        response = await requestServiceMarkPutOrDeleteScannerDispatched(api_for_data)
        errors = getErrorResponseSpread(response)
    }
    let alert = null;
    if (Array.isArray(response)) {
        alert = response?.filter(e => e?.data)?.map(e => e?.data?.alert)?.join(". ")
    } else {
        alert = response?.data?.alert?.join(". ")
    }
    if (alert) {
        toast.success(alert)
    }
    if (errors) {
        dispatch(dispatcherErrorThunk({error: errors}));
    }
}

function createDataForToggleMarkScanner(api_for_data) {
    let api_data = {
        id: api_for_data.link,
        shift: api_for_data.shift,
        worker_group: api_for_data.worker_group,
        user: localStorage.getItem(LS_USER_ID),
        equipment_worker: localStorage.getItem(LS_WORKER_ID),
        part_for_service_add: null
    }
    if (api_for_data.hasOwnProperty('service_type_package_id')) {
        if (api_for_data.is_type_order_or_part) {
            return [{
                ...api_data, part_for_service_add: [

                    {
                        id: api_for_data.link,
                        service_add: api_for_data.service_type_package_id
                    }
                ]
            }]
        } else {

            return [{
                ...api_data, service_add: api_for_data.service_type_package_id
            }]
        }
    }
    if (api_for_data.hasOwnProperty('service_type')) {
        if (api_for_data.is_type_order_or_part) {
            return api_for_data.service_type.map(id => {
                return {
                    ...api_data,
                    part_for_service_add: [

                        {
                            id: api_for_data.link,
                            service_add: id
                        }
                    ]
                }
            })
        } else {
            return api_for_data.service_type.map(id => {
                return {
                    ...api_data,
                    service_add: id,
                }
            })
        }
    }

}

//**
// Выбор смены и бригады. Запросить данные о работниках
// Получить всех работников которые входят в бригаду для получения доступных услуг которые могут отмечать
// или одного сотрудника (дописать логику если у нас не пользователь а работник в системе)
// */
//::TODO дописать логику если у нас не пользователь а работник в системе
export const scannerReadyMarkPutOrDeleteMarkThunk = ({
                                                         decode_text, resetText
                                                     }) => async (dispatch, getState) => {
    try {

        dispatch(toggleLoaderStatusAC(true));

        let store_scanner_mark = getState()?.salary_project?.scanner_read_mark;
        let isScannerVideo = store_scanner_mark.start_scanner;
        let isScannerText = store_scanner_mark.start_scanner_text;

        let filter = store_scanner_mark.filter;
        let shift_form_values = store_scanner_mark.shift_form_values;

        let is_type_need_confirm = !!Number(filter?.type_need_confirm);
        let is_type_package_or_service = !!Number(filter?.type_package_or_service);

        let data_for_api = {
            is_type_order_or_part: !!Number(filter?.type_order_or_part),
            is_type_add_or_delete_mark: !!Number(filter?.type_add_or_delete_mark),
            shift: shift_form_values.shift?.salary_shift_id,
            worker_group: shift_form_values.worker_group,
            link: decode_text,
            is_service_type_package: !is_type_package_or_service,
        }
        if (is_type_package_or_service) {
            data_for_api['service_type'] = filter?.service_type;
        }


        if (is_type_need_confirm) {
            //::TODO additional req for get data
            let form_data = createFormData({id: decode_text})
            const response = await PutProdMarkDetailAPI.getInfoAboutOrderBeforeMarkPut(form_data);
            console.log(response)

            dispatch(scannerReadMarkToggleAllAC({
                confirm_modal: {
                    isOpen: true, information: response?.data, data_for_api: data_for_api
                }
            }))
            if (!response?.error) {
                if (isScannerText) resetText();
            }


            dispatch(toggleLoaderStatusAC(false));
            return
        }
        await requestMarkPutOrDeleteScannerDispatched(data_for_api, dispatch)
        if (!isScannerText) {
            dispatch(scannerReadMarkToggleAllAC({start_scanner: true}));
        }

        if (isScannerText && !is_type_need_confirm) {
            resetText()
        }
        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}


export const scannerConfirmReadyMarkPutOrDeleteMarkThunk = (data_for_api) => async (dispatch, getState) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        let store_scanner_mark = getState()?.salary_project?.scanner_read_mark;
        let isScannerText = store_scanner_mark.start_scanner_text;
        if (!isScannerText) {
            dispatch(scannerReadMarkToggleAllAC({start_scanner: false}));
        }

        await requestMarkPutOrDeleteScannerDispatched(data_for_api, dispatch)
        dispatch(scannerReadMarkToggleAllAC({
            confirm_modal: {
                isOpen: false, information: null, data_for_api: null
            },
            start_scanner: isScannerText ? false : true
        }))
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}


/**
 * getPartsInformationForProductionMarksByOrder.получаем данные для отметок упаковки по деталям
 *
 * @param {number} order_id  -
 * @param {object || null} filter  -
 */
export const getPackingPartsInformationForProductionMarksByOrder = (order_id, filter = null) => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));

        let response = await getPartForOrderMarks(order_id, filter)
        if (response?.error && !response?.data) {
            dispatch(dispatcherErrorThunk({error: response.error}))
        }
        if (response?.data && !response?.error) {
            let payload = {
                isOpen: true, data: response.data
            }

            dispatch(readyMarkPackingPartForOrderModalAC(payload));
        }
        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e));

    }
}


//** Отправка запросв на отметку упаковки */
async function dispatchedPutPackingMarkToDetailAndGetOrders(parallel_request_entry, dispatch) {

    const promises = parallel_request_entry.map(async (api_data, index) => {
        return await PutProdMarkDetailAPI.addPackingMarkToDetailById(api_data)
    });
    let response = await Promise.all(promises)
    let alert = response?.filter(e => e?.data?.alert)?.map(e => e?.data?.alert)?.join(". ")
    if (alert) {
        toast.success(alert)
    }
    let errors = getErrorResponseSpread(response)
    if (errors) {
        dispatch(dispatcherErrorThunk({error: errors}));
    }
    return new Promise((res) => res(true))
}

//** Отправка запросв на УДАЛЕНИЕ отметкИ упаковки */
async function dispatchedDeletePackingMarkToDetailAndGetOrders(parallel_request_entry, dispatch) {
    const promises = parallel_request_entry.map(async (api_data, index) => {
        console.time(`delete_point_${index}`);
        let response = await PutProdMarkDetailAPI.removePackingMarkToDetailById(api_data)
        console.timeEnd(`delete_point_${index}`);
        return response
    });
    let response = await Promise.all(promises)
    let alert = response?.filter(e => e?.data?.alert)?.map(e => e?.data?.alert)?.join(". ")
    if (alert) {
        toast.success(alert)
    }
    let errors = getErrorResponseSpread(response)
    if (errors) {
        dispatch(dispatcherErrorThunk({error: errors}));
    }
    return new Promise((res) => res(true))

}

export const togglePackingMarkToDetailByIdOrderThunk = ({
                                                            put = null,
                                                            remove = null,
                                                            filter,
                                                            order_id
                                                        }) => async (dispatch, getState) => {
    try {

        dispatch(toggleLoaderStatusAC(true));
        if (Array.isArray(put) && put?.length) {
            await dispatchedPutPackingMarkToDetailAndGetOrders(put, dispatch)
        }
        if (Array.isArray(remove) && remove?.length) {
            await dispatchedDeletePackingMarkToDetailAndGetOrders(remove, dispatch)
        }
        await dispatch(getPackingPartsInformationForProductionMarksByOrder(order_id, filter))
        dispatch(toggleLoaderStatusAC(false));


    } catch (e) {
        dispatch(dispatcherErrorThunk(e));

    }
}

export const removeALLPackingMarkFromOrderThunk = (api_data) => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const response = await PutProdMarkDetailAPI.removeALLPackingMarkFromOrder(api_data)
        if (response.hasOwnProperty('error') && response?.error) {
            dispatch(dispatcherErrorThunk({error: response.error}));
            return
        }
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e));

    }
}

