import React from 'react';

const TextScanner = ({getT, scanning_text,textScannerVal, setTextScannerVal, onToggleButton, qrCodeSuccessCallback}) => {
    const onSubmit = (e) => {
        e.preventDefault();
        qrCodeSuccessCallback(textScannerVal);
    }

    return (
        <div className={"mb-3"}>
            {scanning_text ? (
                <div className="d-flex justify-content-center mb-2">
                    <button className={"btn btn-danger"} onClick={() => {
                        onToggleButton(false)
                    }}> {getT("Остановить скан")}</button>
                </div>
            ) : (
                <div className="d-flex justify-content-center mb-2">
                    <button className={"btn btn-info"} onClick={() => {
                        onToggleButton(true)
                    }}>{getT("Сканировать")}</button>
                </div>
            )}
            {scanning_text && <form onSubmit={onSubmit}>
                <div className="row justify-content-center">
                    <input type="text" className={"form-control col-8"}
                           onChange={(e) => setTextScannerVal(e.target.value)}
                           value={textScannerVal}/>
                    <button className="btn col-auto" type={"submit"}>
                        <svg fill="#000000" height="20px" width="20px" version="1.1" id="Layer_1"
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 330 330">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path id="XMLID_222_"
                                      d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001 c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213 C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606 C255,161.018,253.42,157.202,250.606,154.389z"></path>
                            </g>
                        </svg>
                    </button>
                    {/*<div className="">{getT("о"}</div>*/}
                </div>

            </form>}

        </div>
    );
};

export default TextScanner;